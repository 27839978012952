import React, { useState, useContext, useEffect } from "react";
import { Row, Col, FormGroup, Button, Label, Input } from "reactstrap";
import useSWR from "swr";
import fetcher from "../../utilities/fetcher";
import Select, { components } from "react-select";
import SalonFinderContext from "./SalonFinderContext";
import { useLocation } from "react-router-dom";

function DropdownIndicator(props) {
  return (
    <components.DropdownIndicator {...props}>
      <div className="arrow-down"></div>
    </components.DropdownIndicator>
  );
}

export default function SalonSearchForm({ onSearch }) {
  const [search, setSearch] = useState("");
  const [brand, setBrand] = useState([]);

  // let _brand = "";
  // if (location.search) {
  //   var query = new URLSearchParams(location.search);
  //   country = query.get("country");
  //   _brand = query.get("brands");
  // }

  const { data } = useSWR("/api/brand/get", fetcher);
  const options =
    data && data.map((item) => ({ value: item.id, label: item.name }));

  const handleSearch = (event) => setSearch(event.target.value);
  const handleBrand = (selected) => {
    debugger;
    if (selected) {
      setBrand(selected.map((item) => item.value));
    } else {
      setBrand([]);
    }
  };
  const handelSubmit = () => {
    onSearch({ search, brand });
  };

  const { selectedLanguage, setBrands } = useContext(SalonFinderContext);

  const handleKeyEnter = (event) => {
    if (event.keyCode === 13) {
      handelSubmit();
    }
  };

  useEffect(() => {
    onSearch({ search, brand });
  }, [brand]);

  useEffect(() => {
    if (data) {
      setBrands(data);
    }
  }, [data]);

  var fontAdjustment = `1rem`;
  var paddingAdjustment = `16px 20px`;

  if (
    selectedLanguage?.["find_salon"]?.value.length > 12 &&
    selectedLanguage?.["find_salon"]?.value.length < 16
  ) {
    fontAdjustment = `0.9rem`;
  } else if (selectedLanguage?.["find_salon"]?.value.length >= 16) {
    fontAdjustment = `0.8rem`;
    paddingAdjustment = `16px 10px`;
    if (selectedLanguage?.["find_salon"]?.value.length >= 19) {
      paddingAdjustment = `6px 10px`;
    }
  } else {
    fontAdjustment = `1rem`;
  }

  return (
    <Row className="salon-search-form">
      <Col xs="12" xl="8" className="mx-auto text-center">
        <h1>
          {selectedLanguage && selectedLanguage?.["find_salon_near_you"]?.value}
        </h1>
        <Row className="search-form-control">
          <Col>
            <FormGroup style={{ marginBottom: `10px` }}>
              <Label for="search" className="sr-only">
                {selectedLanguage &&
                  selectedLanguage?.["enter_city_zipcode"]?.value}
              </Label>
              <Input
                type="text"
                name="search"
                id="search"
                placeholder={
                  selectedLanguage &&
                  selectedLanguage?.["enter_city_zipcode"]?.value
                }
                onChange={handleSearch}
                onKeyDown={handleKeyEnter}
              />
            </FormGroup>
          </Col>
          <Col>
            <FormGroup style={{ marginBottom: `20px` }}>
              <Label for="brands" className="sr-only">
                {selectedLanguage && selectedLanguage?.["select_brand"]?.value}
              </Label>
              <Select
                isClearable
                isMulti
                options={options}
                placeholder={
                  selectedLanguage && selectedLanguage?.["select_brand"]?.value
                }
                classNamePrefix="react-select"
                styles={{
                  control: (provided) => {
                    return {
                      ...provided,
                      minHeight: `53px`,
                      backgroundColor: `#f2f4f5`,
                      borderRadius: `0`,
                    };
                  },
                }}
                onChange={handleBrand}
                components={{ DropdownIndicator }}
              />
            </FormGroup>
          </Col>
          <Col className="cta-column">
            <FormGroup>
              <Button
                color="dark"
                style={{
                  fontWeight: `700`,
                  textTransform: `uppercase`,
                  fontSize: fontAdjustment,
                  padding: paddingAdjustment,
                  borderRadius: `10px`,
                  backgroundColor: `#000`,
                }}
                className="w-100"
                onClick={handelSubmit}
              >
                {selectedLanguage && selectedLanguage?.["find_salon"]?.value}
              </Button>
            </FormGroup>
          </Col>
        </Row>
      </Col>
    </Row>
  );
}
