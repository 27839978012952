import React, { useContext } from "react";
import { Card, CardBody, CardTitle, Button, CardText } from "reactstrap";
// import icoMaps from "../../assets/images/location.svg";
// import icoPhone from "../../assets/images/call.svg";
// import icoTime from "../../assets/images/time-outline.svg";
import icoMaps2 from "../../assets/images/ico_pin_on_map.svg";
import { CallIcon, PinIcon, ClockIcon } from "../Icons";
import SalonFinderContext from "./SalonFinderContext";

export default function SalonItem({
  id,
  name,
  address,
  contactNo,
  latitude,
  longitude,
  salonBrands,
  isInMap = false,
  opens,
  onSelect,
}) {
  const { selectedSalon, setSelectedSalon, selectedLanguage } =
    useContext(SalonFinderContext);

  const handleSelect = () => {
    setSelectedSalon({ id, lat: latitude, lng: longitude });
  };
  const isActive = selectedSalon.id === id;

  const brands =
    salonBrands && salonBrands.map((salonBrand) => salonBrand.brand.name);

  return (
    <Card key={id} className={`${isActive ? `active` : ``}`}>
      <CardBody>
        <CardTitle>
          <strong>{name}</strong>
          <Button
            color="dark"
            className="show-in-map"
            style={{
              borderRadius: `10px`,
              backgroundColor: `#000`,
            }}
            onClick={handleSelect}
          >
            <img
              src={icoMaps2}
              alt={selectedLanguage?.["view_in_map"]?.value}
            />
            <span style={{ fontSize: `0.75rem`, fontWeight: 700 }}>
              {selectedLanguage?.["view_in_map"]?.value}
            </span>
          </Button>
        </CardTitle>
        <CardText className="mb-3 d-flex">
          <span
            className="mr-2"
            style={{
              marginTop: `-0.1rem`,
              width: `0.875rem`,
              textAlign: `center`,
            }}
          >
            <PinIcon style={isActive && !isInMap ? { fill: `#fff` } : {}} />
          </span>

          <span>{address}</span>
        </CardText>
        {contactNo && (
          <CardText className="mb-3 d-flex">
            <span style={{ marginTop: `-0.1rem` }}>
              <CallIcon
                className="mr-2"
                style={isActive && !isInMap ? { fill: `#fff` } : {}}
              />
            </span>
            <a style={{ color: `inherit` }} href={`tel:${contactNo}`}>
              {contactNo}
            </a>
          </CardText>
        )}

        {opens && (
          <CardText className="mb-3 d-flex">
            <span style={{ marginTop: `-0.1rem` }}>
              <ClockIcon
                className="mr-2"
                style={isActive && !isInMap ? { stroke: `#fff` } : {}}
              />
            </span>
            <span>{opens}</span>
          </CardText>
        )}

        {brands ? (
          brands.length > 0 ? (
            <CardText className="d-flex">
              <strong style={{ flex: `0 0 3.5rem` }}>Brands : </strong>{" "}
              <span>{brands.join(", ")}</span>
            </CardText>
          ) : null
        ) : null}
      </CardBody>
    </Card>
  );
}
